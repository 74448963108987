import Component from '@glimmer/component';

const TAG_NAME_TO_TYPE_MAPPING = {
  b: 'bold',
  i: 'italic',
  strike: 'strike-through',
  u: 'underline',
  br: 'line-break',
  sup: 'superscript',
  sub: 'subscript',

  // Legacy
  em: 'italic',
  strong: 'bold',
  p: 'bold',
  div: 'bold',
  ul: 'bold',
  ol: 'bold',
  li: 'bold',
};

export default class TbRenderInlineElementFormatComponent extends Component {
  /**
   * node (object)
   */

  get type() {
    const node = this.args.node;

    if (!node) return null;

    const { attributes, tagName } = node;

    if (tagName === 'span') {
      const textColor = attributes.findBy('key', 'data-text-color');

      if (textColor) {
        return 'text-color';
      }

      return 'speech';
    }

    return TAG_NAME_TO_TYPE_MAPPING[tagName];
  }

  get componentName() {
    return this.type ? `tb-render-inline/element/format/${this.type}` : null;
  }
}
