import { later } from '@ember/runloop';
import Service from '@ember/service';
import { inject as service } from '@ember/service';

export default Service.extend({
  active: false,

  router: service(),

  contextHelper: service(),

  init() {
    this._super(...arguments);

    this.get('router').on('routeDidChange', (transition) => {
      let active = this.get('active');
      if (transition.to.name != transition.from.name && active) {
        this.turnOff();
      }
    });
  },

  turnOn() {
    document.body.classList.add('entering-focus');
    later(() => {
      this.resetTools();
      document.body.classList.add('is-focusing');
    }, 600);
  },

  turnOff() {
    document.body.classList.remove('is-focusing', 'entering-focus');
    this.set('active', false);
  },

  resetTools() {
    const isWorkspaceOpen =
      this.get('router.currentRouteName').split('.').includes('workspace') ||
      (this.contextHelper.inWorkspaceExercise &&
        !this.get('router.currentRouteName').includes('master.interactives'));

    if (isWorkspaceOpen) {
      this.get('router')
        .replaceWith('master.contents')
        .finally(() => {
          this.set('active', true);
        });
    } else {
      this.set('active', true);
    }
  },
});
