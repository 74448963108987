import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';
import config from 'babel/config/environment';
import imageSource from 'compton/utils/image-source';
import { load } from 'ember-async-data';
import { all } from 'rsvp';

export default class AttachmentPreviewInternalComponent extends Component {
  /**
   * allowedBooks (array)
   * entity (object)
   */

  get imageUrl() {
    if (
      this.args.entity?.type === 'books' &&
      this.args.entity?.coverImage?.imageUrl
    ) {
      return imageSource(this.args.entity?.coverImage?.imageUrl, {
        preset: 'thumbnail',
      });
    }

    if (this.args.entity?.featured_image) {
      return imageSource(this.args.entity.featured_image, {
        preset: 'thumbnail',
      });
    }

    if (this.args.entity?.coverImage?.imageUrl) {
      return imageSource(this.args.entity?.coverImage?.imageUrl, {
        preset: 'thumbnail',
      });
    }

    if (this.args.entity?.defaultImage) {
      return this.args.entity.defaultImage;
    }

    return null;
  }

  @cached
  get isValidProxy() {
    const promise = all([this.args.entity.book, this.args.allowedBooks]).then(
      ([book, allowedBooks]) =>
        allowedBooks?.map((obj) => obj.id)?.includes(book?.id)
    );

    return load(promise, this);
  }

  get isValid() {
    return this.isValidProxy.isResolved ? this.isValidProxy.value : false;
  }

  get juniorHref() {
    return `${config.juniorUrl}/books/${this.args.entity.id}`;
  }
}
