import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import collectionClose from 'babel/utils/collection-close';
import { nextObject, previousObject } from 'compton/utils/array';

export default class MasterInteractivesPracticesShowController extends Controller {
  @service contextHelper;

  @service router;

  @tracked showCollectionSummary = false;

  get hasPreviousCollection() {
    return this.model.collections?.indexOf(this.model.collection) > 0;
  }

  get hasNextCollection() {
    return !(
      this.model.collections?.indexOf(this.model.collection) >=
      this.model.collections?.length - 1
    );
  }

  @action
  paginateToNextCollection() {
    const next = nextObject(this.model.collections, this.model.collection);
    if (next) {
      this.openCollection(next.id);
    }
  }

  @action
  paginateToPreviousCollection() {
    const previous = previousObject(
      this.model.collections,
      this.model.collection
    );
    if (previous) {
      this.openCollection(previous.id);
    }
  }

  @action
  openExercise(id) {
    return this.router.transitionTo('master.interactives.practices.show', id);
  }

  @action
  openCollection(id) {
    const collection = this.model.collections.findBy('id', id);
    if (collection.template === 'diagnosis_part') {
      return this.router.transitionTo('master.interactives.diagnoses', id);
    }
    return this.router.transitionTo('master.interactives.practices', id);
  }

  @action
  async handleCollectionClose() {
    return collectionClose(this.model, this.contextHelper, this.router);
  }

  @action
  toggleCollectionSummary(val) {
    if (typeof val === 'boolean') this.showCollectionSummary = val;
    else this.showCollectionSummary = !this.showCollectionSummary;
  }
}
