import { RecordedSpeechProvider } from 'speaker';

class IltRecordedSpeechProvider extends RecordedSpeechProvider {
  constructor(params) {
    super();

    // Destruct params to class variables
    ({
      articleIds: this._articleIds,
      iltApi: this._iltApi,
      iltDataParser: this._iltDataParser,

      // Injected function to handle ILT:s JWT required to access the articles API.
      getJwt: this._getJwt,

      // Injected function that handles when an ILT error occurs.
      iltError: this._iltError,

      // This flag is necessary to configure some behavior for missing ILT audio
      enablePlayNextOnLoadError: this._enablePlayNextOnLoadError,
    } = params);

    // Will be initialized by customInit()
    this._articles = null;
    this._jwt;
  }

  _fetchArticles(articleIds, jwt) {
    const fetchPromises = articleIds.map((articleId) => {
      return this._iltApi
        .fetchArticle({ articleId, jwt, useBrowserCache: true })
        .then((article) => {
          if (!this._iltDataParser.isArticleExpired(article)) {
            return article;
          } else {
            // console.debug(`Article has expired. Will attempt to fetch again, busting browser cache...`);
            // For expired articles, try to fetch again and disable browser cache for this request
            // If the article fetched is still expired, reject Promise to disable ILT speech for that article
            return this._iltApi
              .fetchArticle({ articleId, jwt, useBrowserCache: false })
              .then((article) =>
                this._iltDataParser.isArticleExpired(article) ? null : article
              );
          }
        })
        .catch(() => {
          // console.debug('ILT Article not found', err);
          return;
        });
    });

    // Filter out any expired (null) articles when all fetch promises are done
    return Promise.all(fetchPromises).then((articles) =>
      articles.filter((article) => article)
    );
  }

  /**
   * Performs any kind of custom init as a first step before initializing any audio sources.
   * For example, fetch data from external APIs and/or apply custom logic to decide how to intialize the audio sources.
   * Must return a Promise that resolves when done. Should NEVER reject, instead handle all errors internally.
   */
  customInit() {
    // Start by resetting articles
    this._articles = null;

    // Get token, and then fetch articles
    return this._getJwt()
      .then((jwt) => {
        this._jwt = jwt;

        if (jwt) {
          // Fetch article data from ILT
          return this._fetchArticles(this._articleIds, jwt)
            .then((articles) => {
              // Note: When successfully fetching articles, we must enable ILT in case it is still null,
              // i.e the initial session value when we don't know yet if the user has ILT access.
              this._articles = articles;
              return articles;
            })
            .catch((errorCode) => {
              console.warn(
                `Could not fetch articles from ILT. Error code:`,
                errorCode
              );
              if (+errorCode === 401) {
                // Notify that an ILT error occured
                this._iltError();
              }
            });
        }
      })
      .catch((error) => {
        // Unexpected error getting/fetching token. Catch the error, but don't disable ILT in this case, to allow us to try again later.
        console.warn(`Unexpected error trying to get a JWT for ILT:`, error);
      });
  }

  /**
   * Makes it possible to skip text block processing for recorded speech audio sources for this
   * provider AND any providers of lower priority.
   * If no special cases are implemented, this method should always return false.
   */
  preventRecordedSpeechForTextBlocks() {
    // ILT has no special cases that needs text blocks to be skipped completely
    return false;
  }

  /**
   * @param textBlockData The custom text block data returned by the DOM helper's getTextBlockRecordedSpeechData() function.
   * @param textBlockHtml The html (string) of the text block. Needed for example to create meta data to enable word/sentence highlighting.
   * @return An array of zero or more configuration objects for the audio sources to be created for the text block.
   * Note: Returning more than one config object means that the recorded speech has been split up into several sound files for that the block.
   * Zero configs (empty array) means that recorded speech will not be available for that the block.
   */
  getTextBlockAudioSourceData(textBlockData, textBlockHtml) {
    // For ILT's recorded speech, we use the article data already fetched in customInit()
    // to find the audio sources to create for each text block, one for each sentence

    const iltSentenceGroupId = textBlockData.recordedSpeechId;

    let audioSources = [];

    // Exclude text blocks that don't have a recordedSpeechId and therefore will never have been sent to ILT (such as headings and image texts)
    if (iltSentenceGroupId) {
      audioSources = this._iltDataParser.getAudioSources(
        this._articles,
        iltSentenceGroupId,
        textBlockHtml
      );
    }

    if (audioSources.length === 0) {
      // NOTE! This is a quick fix to allow text blocks without ILT audio to be clickable the same way Digilär audio works.
      // By specifying an invalid audio URL, a load error will occur, and the speaker will then continue with the next audio source instead, or fallback to TTS.
      if (this._enablePlayNextOnLoadError) {
        audioSources = [
          {
            url: '/no-ilt-audio-found.mp3',

            // Custom data to mark the audio source as invalid
            data: {
              invalid: true,
            },
          },
        ];
      }
    }

    return audioSources;
  }

  /**
   * Specifies if all recorded speech URLs should be verified by HTTP GET requests.
   * Set to false for optimization if you're sure that all recorded speech URLs are already valid
   */
  shouldVerifyRecordedSpeechUrls() {
    // Note: We should never have to validate the ILT URLs since they should always exist.
    return false;
  }

  /**
   * Called when an audio source from the current provider is starting to play.
   * Note, not called when play is resumed after pausing
   */
  playStarted(event) {
    return this._playbackRoyaltyHandler(event);
  }

  /**
   * Called when an audio source from the current provider has finished playing.
   */
  playEnded(event) {}

  /////////////////////////////////////////////////////////////////////////
  // Private functions

  _playbackRoyaltyHandler(speakerEvent) {
    // Here we just read the locally stored token which have already been fetched and validated by the customInit() function.
    const jwt = this._jwt;

    // Use data from the play event to make a callback to ILT's playback royalty API
    const { articleId, recordingId } = speakerEvent.recordedSpeechProviderData;

    this._iltApi.reportListening(articleId, recordingId, jwt);
  }
}

export default IltRecordedSpeechProvider;
