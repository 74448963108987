import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<DcIcon data-test-entity-icon @name={{this.name}} ...attributes />", {"contents":"<DcIcon data-test-entity-icon @name={{this.name}} ...attributes />","moduleName":"babel/components/entity-icon/index.hbs","parseOptions":{"srcName":"babel/components/entity-icon/index.hbs"}});
import Component from '@glimmer/component';

/*

export interface EntityCustomizerSignature {
  Args: {
    entity: EntityModel;
  };
  Element: HTMLElement;
}

*/

const ENTITY_TYPE_TO_NAME_MAPPING = {
  archives: 'archive',
  areas: 'area',
  assignments: 'assignments',
  books: 'book',
  collections: 'collection',
  contents: 'content',
  exercises: 'exercise',
  folders: 'folders',
  glossaries: 'glossaries',
  interactives: 'interactives',
  sections: 'segmentation',
  studies: 'study',
  books_junior: 'book',
};

export default class EntityIcon extends Component {
  get name() {
    return ENTITY_TYPE_TO_NAME_MAPPING[this.args.entity?.type];
  }
}
