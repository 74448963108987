import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class EntityProgressComponent extends Component {
  /**
   * Arguments:
   * model (object)
   * animated (bool)
   * inCollectionView (bool)
   */

  @service assignmentEvents;

  constructor() {
    super(...arguments);

    this.assignmentEvents.on('interactive-updated', this.setInteractivePromise);

    this.setInteractivePromise();
  }

  willDestroy() {
    super.willDestroy(...arguments);

    this.assignmentEvents.off(
      'interactive-updated',
      this.setInteractivePromise
    );
  }

  @tracked interactivePromise = resolve();

  @cached
  get interactiveProxy() {
    return load(this.interactivePromise, this);
  }

  get interactive() {
    return this.interactiveProxy.isResolved
      ? this.interactiveProxy.value
      : null;
  }

  @cached
  get collectionOwnerProxy() {
    return load(resolve(this.args.model?.owner), this);
  }

  get collectionOwner() {
    return this.collectionOwnerProxy.isResolved
      ? this.collectionOwnerProxy.value
      : null;
  }

  get checked() {
    return this.interactive?.isCompleted;
  }

  get variant() {
    return this.checked ? 'success' : 'default';
  }

  get inActiveCarousel() {
    return (
      this.args.inCollectionView &&
      this.args.model?.isCarousel &&
      !this.interactive?.ended
    );
  }

  get value() {
    if (this.inActiveCarousel) {
      return this.interactive?.numSubmittedAttemptAnswers;
    }

    if (this.interactive?.isDiagnosis) {
      return this.interactive?.numStartedAnswers;
    }

    return this.interactive?.numSubmittedAnswers;
  }

  get total() {
    if (this.inActiveCarousel) {
      return this.interactive?.numAttemptExercises;
    }

    return this.interactive?.numExercises;
  }

  get progress() {
    if (!isNaN(this.value) && !isNaN(this.total) && this.total > 0) {
      return (this.value / this.total) * 100;
    }

    return this.interactive?.progress ?? 0;
  }

  @action
  setInteractivePromise(collectionId) {
    if (collectionId && this.args.model?.id !== collectionId) return;

    this.interactivePromise = this.assignmentEvents
      .getOrCreateInteractiveForCollection(this.args.model)
      .then(({ interactive }) => interactive);
  }
}
