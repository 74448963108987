/* eslint-disable ember/no-jquery */
import { storageFor } from 'ember-local-storage';
import $ from 'jquery';
import { WebPageTranslationDetector } from 'speaker';

import DigilarDomElementsConfig from './DigilarDomElementsConfig';
import DigilarLanguageUtil from './DigilarLanguageUtil';
import DigilarSpeakerType from './DigilarSpeakerType';

const DigilarSpeakerDOMElements = {
  STORY_IMAGE_SPEAKER_CONTROLS: {
    elementName: 'div',
    className: 'speaker-story-image-controls',
  },
  AUDIO_PROVIDER_INFO: {
    elementName: 'div',
    className: 'speaker-audio-provider-info',
  },
};

const toJQuerySelector = (domElementConfig) => {
  let selector = '';
  if (domElementConfig.elementName) {
    selector = domElementConfig.elementName;
  }
  if (domElementConfig.className) {
    selector += `.${domElementConfig.className}`;
  }
  return selector;
};

/**
 * Digilär custom DOM functionality for the speaker. Primarily used by the generic class SpeakerDomHandler from the speaker repo.
 */
class DigilarDomHelper {
  @storageFor('play-settings') speakerSettings;

  constructor(params) {
    this._domElementsConfig = null;

    // Destruct params to class variables
    ({
      getContextData: this._getContextData,
      getSingleRecordedSpeechAudioSourceConfig:
        this._getSingleRecordedSpeechAudioSourceConfig,
      speakerConfig: this._speakerConfig,
    } = params);
  }

  _textBlockSelectors() {
    let generalSelector = '[data-speaker-block], [data-id]';
    let additionalSelectors = [];

    // Excluding the images from the "speaker text blocks" requires some special attention.
    // This is necessary to be able to add play/pause controls only to the captions and not to the images
    if (this._speakerConfig.UI.Images.excludeImageFromSpeakerControls()) {
      if (!this._speakerConfig.UI.Images.excludeCaptionsFromSpeakerControls()) {
        // Add caption selector if captions are not excluded
        additionalSelectors.push(
          toJQuerySelector(DigilarDomElementsConfig.IMAGE_CAPTIONS)
        );
        additionalSelectors.push(
          toJQuerySelector(DigilarDomElementsConfig.VIDEO_CAPTIONS)
        );
        additionalSelectors.push(
          toJQuerySelector(DigilarDomElementsConfig.INTERACTIVE_IMAGE_CAPTIONS)
        );
        additionalSelectors.push(
          toJQuerySelector(DigilarDomElementsConfig.AUDIO_CAPTIONS)
        );
        additionalSelectors.push(
          toJQuerySelector(DigilarDomElementsConfig.CODE_BLOCK_CAPTION)
        );
        additionalSelectors.push(
          toJQuerySelector(DigilarDomElementsConfig.CANVAS_CAPTION)
        );
      }

      const isRecordedSpeech =
        this._speakerConfig.getContextData().digilarRecordedSpeechEnabled ||
        this._speakerConfig.getContextData().iltRecordedSpeechEnabled;

      // Modify the general selector to exclude the image elements, since we are now only interested in the captions,
      // and we don't want to create duplicate audio sources
      let imagesSelector = toJQuerySelector(DigilarDomElementsConfig.IMAGES);
      let interActiveImagesSelector = toJQuerySelector(
        DigilarDomElementsConfig.INTERACTIVE_IMAGES
      );
      let videoSelector = toJQuerySelector(DigilarDomElementsConfig.VIDEO);
      let audioSelector = toJQuerySelector(DigilarDomElementsConfig.AUDIO);
      let codeSelector = toJQuerySelector(DigilarDomElementsConfig.CODE_BLOCK);
      let listSelector = toJQuerySelector(DigilarDomElementsConfig.LIST);
      let canvasSelector = toJQuerySelector(DigilarDomElementsConfig.CANVAS);
      let assignmentSelector = isRecordedSpeech
        ? toJQuerySelector(DigilarDomElementsConfig.ASSIGNMENT_LINK)
        : null;
      generalSelector = `${generalSelector}:not(${imagesSelector}):not(${interActiveImagesSelector}):not(${videoSelector}):not(${audioSelector}):not(${codeSelector}):not(${canvasSelector}):not(${assignmentSelector}):not(${listSelector} ${listSelector})`;
    }

    if (!this._speakerConfig.TextProcessing.shouldExcludeModalDialogTitle()) {
      additionalSelectors.push(
        toJQuerySelector(DigilarDomElementsConfig.MODAL_DIALOG_TITLE)
      );
    }

    // In exercises, exclude tables from being read
    if (this._getContextData().contentType === 'practice') {
      // Modify the general selector to exclude any table components within the answer part of the exercise.
      generalSelector = `${generalSelector}:not(${toJQuerySelector(
        DigilarDomElementsConfig.EXERCISE_MAIN_ASSIGNMENT
      )} ${toJQuerySelector(DigilarDomElementsConfig.DATA_TABLE)})`;
    }

    // Exclude VR images and only include captions
    // If we were to exclude VR images in getExcludedDomElements() we would also exclude the captions since they are children of the VR images.
    //
    // Modify the general selector to exclude the VR image elements...
    generalSelector = `${generalSelector}:not(${toJQuerySelector(
      DigilarDomElementsConfig.VR_IMAGE
    )})`;
    // ...and then include only the captions instead.
    additionalSelectors.push(
      toJQuerySelector(DigilarDomElementsConfig.VR_IMAGE_CAPTION)
    );

    additionalSelectors.push('[data-speaker-pause-for]');

    // Merge the general selector with the additional selectors
    return [generalSelector, ...additionalSelectors];
  }

  /////////////////////////////////////////////////////////////////////
  //
  // BEGIN: "Interface methods" that will be called by SpeakerDomHandler
  //

  getTextBlockRecordedSpeechData($textBlock) {
    let dataId = $textBlock.attr('data-id');

    // If the text block itself does not have a data-id attribute, then check if any ancestor has it instead if configured to do so.
    if (
      !dataId &&
      this._speakerConfig.RecordedSpeech.searchAncestorsForDataId()
    ) {
      // console.debug(`text block does not have data-id attribute. Checking ancestors...`, $textBlock.text());
      const $ancestorWithDataId = $textBlock.closest('[data-id]').first();
      if ($ancestorWithDataId.length > 0) {
        dataId = $ancestorWithDataId.attr('data-id');
        // console.debug(`Using ancestor data-id ${dataId}.`);
      }
    }

    let recordedReadingUrl = $textBlock.attr('data-reading');

    if (recordedReadingUrl === 'none') {
      recordedReadingUrl = null;
    }

    return {
      recordedSpeechId: dataId || null,
      recordedReadingUrl: recordedReadingUrl,
    };
  }

  // Extract all data from a text block DOM element necessary to configure TTS providers for that element.
  // Can return anything. The returned data will be injected to other customized functions.
  getTextBlockTtsData($textBlock) {
    const getFallbackExcludedElementConfigs = () => {
      let excludedElements = [];

      // Some speaker specific elements must be excluded from TTS fallback for Digilär's recorded speech
      excludedElements.push(DigilarDomElementsConfig.IMAGE_CAPTIONS);
      excludedElements.push(DigilarDomElementsConfig.VIDEO_CAPTIONS);
      excludedElements.push(DigilarDomElementsConfig.AUDIO_CAPTIONS);
      excludedElements.push(
        DigilarDomElementsConfig.INTERACTIVE_IMAGE_CAPTIONS
      );
      excludedElements.push(DigilarDomElementsConfig.CONTENT_TITLE);

      return excludedElements;
    };

    // Check if the $textBlock is a child of, or ancestor of, any of the elements that should be excluded
    const disableFallback = getFallbackExcludedElementConfigs().some(
      (elementConfig) => {
        const excludedElementSelector = `${elementConfig.elementName}.${elementConfig.className}`;

        // Exclude if the text block is an excluded element
        return $textBlock.is(excludedElementSelector);
      }
    );

    return {
      // The ttsTextId will be sent to the Polly backend to be used when naming the audio files.
      ttsTextId: $textBlock.attr('data-id') || null,

      // The disableFallback property will be checked by DigilarAudioSourceHandler to exclude TTS audio sources.
      disableFallback,
    };
  }

  hasUnresolvedTextBlocks(dom) {
    return dom?.querySelectorAll('[data-reading-loading]')?.length > 0;
  }

  getTextBlockLanguage($textBlock) {
    // Get language from text block if specified
    let lang = $textBlock.attr('data-language') || null;

    if (lang) {
      lang = DigilarLanguageUtil.parseLanguage(lang);
    }

    return lang;
  }

  findRenderedTextBlockElements(dom) {
    this._addPauseElements();
    let { contentType, exerciseType } = this._getContextData();

    let additionalTextBlockSelectors = [];

    // Note: This is example code for how we can create speech for specific parts of exercises.
    // Either extend with more functionality, or add data-id attribute to the elements that should be turned in to speech.
    if (contentType === 'practice') {
      switch (exerciseType) {
        case 'sort-match':
          additionalTextBlockSelectors = ['div.alternative'];
          break;
        default:
          break;
      }
    }

    let allTextBlockSelectors = this._textBlockSelectors().concat(
      additionalTextBlockSelectors
    );

    let $textBlocks = $(dom).find(allTextBlockSelectors.join(','));

    return $textBlocks;
  }

  _addPauseElements() {
    const pauseElements = Array.from(
      document.querySelectorAll('[data-speaker-pause]')
    );

    const createPauseElement = (pauseFor) => {
      const pauseSpan = document.createElement('span');
      pauseSpan.setAttribute('hidden', true);
      pauseSpan.dataset.speakerPauseFor = pauseFor;
      return pauseSpan;
    };

    pauseElements.forEach((element) => {
      // Only add pause span to elements that have none.
      if (!element.querySelector('[data-speaker-pause-for]')) {
        // We need to create a wrapping span otherwise 'replaceHtmlElementByAttribute' in
        // digilar-general.js won't find our pause element.
        const pauseWrapper = createPauseElement(element.dataset.speakerPause);
        const pause = createPauseElement(element.dataset.speakerPause);
        // Need to populate the span with something the speaker doesn't read, Acapela reads html entities such as &nbsp;
        pause.textContent = '{{}}';
        pauseWrapper.appendChild(pause);
        element.appendChild(pauseWrapper);
      }
    });
  }

  filterRenderedTextBlockElements(dom, $textBlocks) {
    // No need to do any custom filtering
    return $textBlocks;
  }

  shouldExcludeTextBlock($textBlock) {
    let { contentType, exerciseType } = this._getContextData();

    // Note! This is just an example of how text blocks can be excluded based on context data
    /*
    if (contentType === 'practice') {
      if ($textBlock.text().trim().length < 5) {
        return true;
      }
    }
    */

    return false;
  }

  isTextBlockRendered($textBlock) {
    let isRendered = true;

    // Special handling for content titles
    if ($textBlock.hasClass(DigilarDomElementsConfig.CONTENT_TITLE.className)) {
      if ($textBlock.text().trim().length === 0) {
        return false;
      }
    }

    // Check that images have received their src attribute
    if ($textBlock.hasClass(DigilarDomElementsConfig.IMAGES.className)) {
      $textBlock
        .find(toJQuerySelector(DigilarDomElementsConfig.IMAGE_INNER))
        .each(function () {
          let hasSrc = $(this)[0].hasAttribute('src');
          if (!hasSrc) {
            return (isRendered = false);
          }
        });

      return isRendered;
    }

    return isRendered;
  }

  getExcludedDomElements() {
    let excludedElements = [];

    if (this._speakerConfig.TextProcessing.shouldExcludeImages()) {
      excludedElements.push(DigilarDomElementsConfig.IMAGES);
    } else if (this._speakerConfig.TextProcessing.shouldExcludeImageSources()) {
      excludedElements.push(DigilarDomElementsConfig.IMAGE_SOURCES);
    }

    if (this._speakerConfig.TextProcessing.shouldExcludeTips()) {
      excludedElements.push(DigilarDomElementsConfig.TIPS);
    }

    if (this._speakerConfig.TextProcessing.shouldExcludeImageGalleries()) {
      excludedElements.push(DigilarDomElementsConfig.IMAGE_GALLERY);
    }

    if (this._getContextData().contentType !== 'practice') {
      // This makes sure that the main speaker from chapter/story/workflow does not try to create speech for the exercises or workspace
      // which will have their own speaker instances.

      excludedElements.push(DigilarDomElementsConfig.WORKSPACE);
    }

    // Always exclude the following elements
    excludedElements.push(DigilarDomElementsConfig.POPOVER_DIALOGS);
    //excludedElements.push(DigilarDomElementsConfig.AUDIO);
    //excludedElements.push(DigilarDomElementsConfig.VIDEO);
    excludedElements.push(DigilarSpeakerDOMElements.AUDIO_PROVIDER_INFO);

    excludedElements.push(DigilarDomElementsConfig.COLLECTION_EXERCISE_INDEX);

    return excludedElements;
  }

  cleanUpDom(domHandler, contextDomElement) {
    // Clean up the section elem (when in story mode and recorded speech for entire section)
    domHandler._cleanUpSpeakerElems(this.findSectionElem(contextDomElement));

    // Delete any custom elements that have been added in applyCustomSpeakerMarkup
    $(contextDomElement).find(`[data-speaker-audio-provider-info]`).remove();
  }

  beforeApplyTextMetaData(domHandler, $textBlock) {
    // Remove any popovers that may have been opened, or else they will mess up the html when meta data is applied
    // because the popover wasn't part of the original text/html that was used to generate the meta data.
    let $popovers = $textBlock.find(
      `.${DigilarDomElementsConfig.POPOVER_DIALOGS.className}`
    );
    $popovers.remove();
  }

  getAudioSourceIndex(initializedAudioSources) {
    const contextData = this._getContextData();
    let { section, contentType } = contextData;
    let index = initializedAudioSources.findIndex((source) => {
      return (
        source.textToSpeechConfig?.textToSpeechData?.ttsTextId ===
        section.document_id
      );
    });
    if (contentType === 'chapter' && index < 2) {
      index = 0;
    }
    return index;
  }

  getFirstVisibleAudioSourceIndex(initializedAudioSources) {
    const index = initializedAudioSources.findIndex((source) => {
      const element = document.querySelector(
        `[data-speaker-text-id='${source.id}']`
      );
      return (
        element &&
        element.getAttribute('hidden') !== 'true' &&
        this._isElementInViewport(element)
      );
    });
    return index;
  }

  _isElementInViewport(el) {
    const { viewportTop } = this._getContextData().viewportUtils;
    const { top } = el.getBoundingClientRect();

    const viewTop = this._insideOfPopupOrExercise(el) ? 0 : viewportTop();
    return top >= viewTop;
  }

  shouldAutoSelectFirstTextBlock() {
    const contextData = this._getContextData();
    let { contentType, mode } = contextData;

    if (contentType === 'chapter' && mode === 'scroll') {
      return true;
    }
    return false;
  }

  isPageRendered(speakerType, dom) {
    const contextData = this._getContextData();

    let { contentType, onlyImages } = contextData;

    // When viewing only images in a story, make sure that the image has been rendered
    if (contentType === 'story' && onlyImages) {
      let $storyImage = this.findStoryImageElem(dom);
      if ($storyImage.length === 0) {
        return {
          ready: false,
          message: `Story image not found`,
        };
      }
    }

    return {
      ready: true,
    };
  }

  _applyProviderInfoForAudioSource($elemsToApplyTo, audioSource) {
    if ($elemsToApplyTo && $elemsToApplyTo.length > 0) {
      $elemsToApplyTo.each(function () {
        let $elemToApplyTo = $(this);

        const hasProviderInfoBeenApplied =
          $elemToApplyTo.children(`[data-speaker-audio-provider-info]`).length >
          0;

        if (!hasProviderInfoBeenApplied) {
          // Pick relevant information from the audio source that we need to expose in the DOM
          const isRecordedSpeech = audioSource.isRecordedSpeech;
          const isLegacyRecordedSpeech = !!(
            isRecordedSpeech &&
            audioSource.recordedSpeechConfig.eventData.singleRecordedSpeechFile
          );
          const provider = isRecordedSpeech
            ? audioSource.recordedSpeechConfig.recordedSpeechProviderId
            : audioSource.textToSpeechConfig.textToSpeechProviderId;

          const providerInfoHtml = () => {
            const providerNames = {
              ilt: 'Inläsningstjänst',
              digilar: 'Digilär',
              acapela: 'Acapela',
              polly: 'AWS Polly',
            };

            // Change the content of the returned div as needed.
            // Adding attributes and classes is also ok to do.
            return `
              <div class="speaker-audio-provider-info" data-speaker-audio-provider-info data-speaker-provider-id="${provider}" data-speaker-recorded-speech="${isRecordedSpeech}" data-speaker-legacy-recorded-speech="${isLegacyRecordedSpeech}" >
                <span>${providerNames[provider] || ''}</span>
              </div>
            `.trim();
          };

          const $providerInfoElem = $(providerInfoHtml());

          $elemToApplyTo.prepend($providerInfoElem);
        }
      });
    }
  }

  applyCustomSpeakerMarkup(
    speakerSandbox,
    initializedAudioSources,
    speakerControls
  ) {
    let { domHandler } = speakerSandbox;
    let { contentType, onlyImages } = this._getContextData();

    let allowDefaultMarkupToBeApplied = true;

    // In story mode, always add provider info to the story image element
    if (contentType === 'story') {
      const $storyImageElem = this.findStoryImageElem(
        domHandler.getSpeakerContextDomElement()
      );

      // Use only the first audio source to add provider info (it should be the same for all audio sources)
      if (initializedAudioSources.length > 0) {
        this._applyProviderInfoForAudioSource(
          $storyImageElem,
          initializedAudioSources[0]
        );
      }
    }

    // If in story content with texts hidden, also add speaker controls to the image
    let textsHidden = contentType === 'story' && onlyImages;
    if (textsHidden) {
      this.applySpeakerMarkUpToStoryImage(domHandler, {
        numberOfAudioSources: initializedAudioSources.length,
        highlightAsSelected: false,
        highlightAsPlaying: false,
      });
      this.addSpeakerControlsToStoryImage(speakerSandbox, speakerControls);
    }

    // Add speaker controls to the text block(s), even if not visible to be able to turn "only images" on/off without having to reset speaker to get controls and text meta data working again
    // Handle special case here when there is only a single recorded speech audio source for a story/section but several text block components.
    // In that case, only one set of controls should be created for the whole section
    let singleRecordedSpeechConfig =
      this._getSingleRecordedSpeechAudioSourceConfig();
    let isSingleRecordedSpeechAudioSource =
      singleRecordedSpeechConfig &&
      initializedAudioSources.length === 1 &&
      initializedAudioSources[0].id === singleRecordedSpeechConfig.id;

    if (isSingleRecordedSpeechAudioSource) {
      this.applySpeakerMarkUpToSection(domHandler);
      this.addSpeakerControlsToSection(speakerSandbox, speakerControls);
      const $sectionElem = this.findSectionElem(
        domHandler.getSpeakerContextDomElement()
      );

      // Use only the first audio source to add provider info (it should be the same for all audio sources)
      if (initializedAudioSources.length > 0) {
        this._applyProviderInfoForAudioSource(
          $sectionElem,
          initializedAudioSources[0]
        );
      }

      // Skip default text block markup in SpeakerContext
      allowDefaultMarkupToBeApplied = false;
    }

    // In the normal case, add provider info to each text block (component) matching an audio source
    const $domContextRoot = $(domHandler.getSpeakerContextDomElement());

    initializedAudioSources.forEach((audioSource) => {
      // Skip all but the first part for multi-part recorded speech (ILT)
      // (Non-multipart audio will only have a single part with index 0.)
      if (
        audioSource.isRecordedSpeech &&
        audioSource.recordedSpeechConfig.recordedSpeechPartIndex > 0
      ) {
        return;
      }

      const $textBlock = $domContextRoot
        .find(`[data-speaker-text-id="${audioSource.id}"]`)
        .first();

      this._applyProviderInfoForAudioSource($textBlock, audioSource);
    });

    return allowDefaultMarkupToBeApplied;
  }

  preventTextBlockClicksForJQuerySelectors() {
    return ['button', 'a', 'input'];
  }

  /**
   * Scrolls given text block DOM element into view
   * @param {*} textBlockElem The text block DOM element to scroll to
   */
  scrollToTextBlock(textBlockElem) {
    if (!textBlockElem) {
      return;
    }

    if (textBlockElem.dataset.speakerScrollTo) {
      textBlockElem = textBlockElem.parentElement;
    }

    if (textBlockElem.dataset.speakerPauseFor) {
      this._handlePauseElementScroll(
        textBlockElem,
        parseInt(textBlockElem.dataset.speakerPauseFor)
      );
      return;
    }

    if (this._insideOfPopupOrExercise(textBlockElem)) {
      const scrollToElement = textBlockElem.nextElementSibling || textBlockElem;

      scrollToElement.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    } else if (WebPageTranslationDetector.isTranslated()) {
      textBlockElem.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      });
    } else {
      let { viewportTop, viewportBottom, elemOffset, scrollTo } =
        this._getContextData().viewportUtils;

      const top = window.pageYOffset + viewportTop();
      const bottom = window.pageYOffset + window.innerHeight - viewportBottom();
      const offset = elemOffset(textBlockElem);
      const height = textBlockElem.offsetHeight;

      if (offset < top || offset + height > bottom) {
        scrollTo(textBlockElem);
      }
    }
  }

  _handlePauseElementScroll(textBlockElem, pauseFor) {
    const SPEAKER_TEXT_BLOCK_SELECTED = 'speaker-text-block-selected';
    const parent = textBlockElem.parentElement;

    // No need to "select" pause element if it is already selected.
    if (parent.classList.contains(SPEAKER_TEXT_BLOCK_SELECTED)) {
      return;
    }

    parent.classList.add(SPEAKER_TEXT_BLOCK_SELECTED);
    setTimeout(() => {
      parent.classList.remove(SPEAKER_TEXT_BLOCK_SELECTED);
    }, parseInt(pauseFor * 2)); // Wait a bit extra before highlighting is removed, especially Acapela seems to pause for longer than specified.
    parent.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  /**
   * Checks whether textBlockElement is inside of a popup or exercise
   * @param {*} textBlockElem The text block DOM element to scroll to
   */
  _insideOfPopupOrExercise(textBlockElem) {
    return (
      this.findPopupSpeakerDomContextElem() ||
      textBlockElem.closest('.exercise-view-component') ||
      textBlockElem.closest('.collection-view-editor')
    );
  }

  /**
   * Configuration of the inner html of some speaker control html elements
   */
  speakerControlsHtml() {
    return {
      playButtonInnerHtml: '<i class="nc-icon nc-button-play"></i>',
      pauseButtonInnerHtml: '<i class="nc-icon nc-button-pause"></i>',
    };
  }

  // END: "Interface methods" that will be called by SpeakerDomHandler
  //
  /////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////
  //
  // BEGIN: Digilär specific methods that are NOT required by SpeakerDomHandler.
  //

  getHtmlElementsToPauseAfter() {
    return [
      DigilarDomElementsConfig.SECTION_HEADERS,
      DigilarDomElementsConfig.HEADERS_LEVEL_2,
      DigilarDomElementsConfig.HEADERS_LEVEL_3,
      DigilarDomElementsConfig.LIST_ITEMS,
      DigilarDomElementsConfig.IMAGE_CAPTIONS,
      DigilarDomElementsConfig.VIDEO_CAPTIONS,
      DigilarDomElementsConfig.AUDIO_CAPTIONS,
      DigilarDomElementsConfig.INTERACTIVE_IMAGE_CAPTIONS,
      DigilarDomElementsConfig.DATA_TABLE_CELLS,
      DigilarDomElementsConfig.DATA_TABLE_HEADER_CELLS,
    ];
  }

  getHtmlElementsToRemoveFromTextProcessing() {
    const elementsToRemove = [
      DigilarDomElementsConfig.POPOVER_DIALOGS,
      DigilarDomElementsConfig.WORKSPACE,
      DigilarSpeakerDOMElements.AUDIO_PROVIDER_INFO,
      DigilarDomElementsConfig.ENTITY_THUMBNAILS,
    ];

    if (
      this._speakerConfig.TextProcessing.shouldExcludeMathCode() &&
      !this._speakerConfig.TextProcessing.shouldExcludeTextsContainingMathCode()
    ) {
      elementsToRemove.push(DigilarDomElementsConfig.MATHLIVE);
    }

    if (this._speakerConfig.TextProcessing.shouldExcludeImageSources()) {
      elementsToRemove.push(DigilarDomElementsConfig.IMAGE_SOURCES);
    }

    return elementsToRemove;
  }

  isSpeakerContextWithinWorkflow(speakerContextDomElement) {
    let retVal = false;

    // First, check globally if a workflow exists (because the speaker-context elem will not actually be a descendant of the workflow)
    const $workflow = $(
      `.${DigilarDomElementsConfig.WORKFLOW_INDEX.className}`
    ).first();

    if ($workflow.length > 0) {
      // Ok, a workflow exists. Now we must check that the speaker-context elem is not within the workspace (Arbetsbord) part of the content,
      // in which case it should not be considered to be within the workflow (Arbetsflöde).
      const $workspaceAncestor = $(speakerContextDomElement)
        .parent()
        .closest(toJQuerySelector(DigilarDomElementsConfig.TOOLBAR_WIDGETS))
        .first();
      const isWithinWorkspace = $workspaceAncestor.length > 0;

      retVal = !isWithinWorkspace;
    }

    return retVal;
  }

  isValidTtsElem(htmlString) {
    const $textBlockElem = $(`<span>${htmlString}</span>`);

    // Check if text block contains math code and in that case invalidate it for TTS use.
    if (
      this._speakerConfig.TextProcessing.shouldExcludeTextsContainingMathCode()
    ) {
      const containsMathlive =
        $textBlockElem.find(toJQuerySelector(DigilarDomElementsConfig.MATHLIVE))
          .length > 0;
      if (containsMathlive) {
        // console.debug('Math code detected, invalidating text for TTS:', $textBlockElem.html());
        return false;
      }
    }

    return true;
  }

  findPopupSpeakerDomContextElem() {
    // get all dialog contents except interactiveImage
    const dialogElements = $(
      'div.x-dialog-content:not(:has(.x-interactive-image))'
    );

    const elem =
      dialogElements.get(dialogElements.length - 1) ||
      $('div.x-attachment').get(0) ||
      null;

    if (elem) {
      if (
        $(elem).find('.collection-view').length > 0 ||
        elem.classList.contains('dismissible-tooltip')
      ) {
        // use main speaker for collection-view-modal
        // see digilar/digilar-v2#3041
        return null;
      }
    }

    const toolTip = document.getElementsByClassName('x-tooltip-container');
    if (toolTip && toolTip.length > 0 && !dialogElements.length) {
      return null;
    }

    return elem;
  }

  addSpeakerContextClickListener(speakerContext, contextDomRoot) {
    const $contextDomRoot = $(contextDomRoot);

    $contextDomRoot.click(function (event) {
      // Find the closest speaker context ancestor of the clicked element
      const $contextForClick = $(event.target)
        .closest('[data-speaker-context-uuid]')
        .first();

      const isClickWithinThisContext =
        $contextForClick.length > 0 &&
        $contextForClick.attr('data-speaker-context-uuid') ===
          speakerContext.getUuid();

      // Clicks on some elements should never change focus
      const excludeClicksOnElements = [DigilarDomElementsConfig.WORKFLOW_INDEX];
      const excludeSelector = excludeClicksOnElements
        .map((el) => `${el.elementName}.${el.className}`)
        .join(' ');
      const isWithinExcludedElement =
        $(event.target).closest(excludeSelector).length > 0;

      // Set context focus if click is within context DOM. Ignore other clicks.
      if (isClickWithinThisContext && !isWithinExcludedElement) {
        speakerContext.setContextFocus(true);
      }
    });
  }

  //
  // END: Digilär specific methods that are NOT required by SpeakerDomHandler.
  //
  ////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////
  //
  // BEGIN: Functionality to handle Digilär's story mode, including "only images"
  // and "legacy mode" (legacy mode meaning that there is only one recorded speech file per section,
  // instead of per component)
  //

  findStoryImageElem(dom) {
    // Note: The story image element is not found within speaker-context (dom param) so we search entire document
    return $(
      `${toJQuerySelector(
        DigilarDomElementsConfig.STORY_IMAGE_CONTAINER
      )} ${toJQuerySelector(DigilarDomElementsConfig.STORY_IMAGE)}`
    ).first();
  }

  findSectionElem(dom) {
    let $section = $(dom)
      .find(toJQuerySelector(DigilarDomElementsConfig.STORY_SECTION_CONTENT))
      .children('div')
      .first();
    return $section;
  }

  addSpeakerControlsToStoryImage(speakerSandbox, speakerControls) {
    let { speakerAdapter, domHandler } = speakerSandbox;

    let $storyImage = this.findStoryImageElem(
      domHandler.getSpeakerContextDomElement()
    );
    if ($storyImage.length === 0) {
      return false;
    }

    // For story cinema mode we need to find out which audio is currently selected (if any)
    // This due to the fact that there may be one or many audio sources "hidden" behind a single clickable image
    const getAudioSourceIndex = () =>
      speakerAdapter.getCurrentAudioSource()
        ? speakerAdapter.getCurrentAudioSource().audioSourceIndex
        : 0;

    const $speakerControls = domHandler._addSpeakerControlsToElement(
      $storyImage,
      getAudioSourceIndex,
      speakerControls,
      { selectBeforePlay: false }
    );
    $speakerControls.addClass(
      DigilarSpeakerDOMElements.STORY_IMAGE_SPEAKER_CONTROLS.className
    );

    // Insert the control buttons as the first child of the story image
    $storyImage.prepend($speakerControls);
  }

  _findSpeakerControlsForStoryImage() {
    const $storyImageControls = $(
      toJQuerySelector(DigilarSpeakerDOMElements.STORY_IMAGE_SPEAKER_CONTROLS)
    );

    return $storyImageControls.first();
  }

  cleanUpStoryImage(domHandler) {
    let $storyImage = this.findStoryImageElem(
      domHandler.getSpeakerContextDomElement()
    );

    if ($storyImage.length > 0) {
      domHandler._cleanUpSpeakerElems($storyImage);

      // Note: The control buttons for story images are not found within the scope of the speaker-context element (domHandler.getSpeakerContextDomElement())
      this._findSpeakerControlsForStoryImage().remove();

      // Remove any animations while playing
      this._clearStoryImagePlayingHighlighting(domHandler);
    }
  }

  applySpeakerMarkUpToSection(domHandler) {
    let $section = this.findSectionElem(
      domHandler.getSpeakerContextDomElement()
    );

    if (
      $section.length > 0 &&
      !domHandler._hasTextBlockSpeakerMarkUp($section)
    ) {
      // Always treat the section speech as recorded speech, since that will show animation/progress bar if enabled, even if it's synthetic speech being played.
      let isRecordedSpeech = true;
      let showProgressBar =
        domHandler._shouldShowProgressBarWhenPlaying(isRecordedSpeech);

      domHandler._applySpeakerMarkUpToElem($section, { showProgressBar });
    }
  }

  addSpeakerControlsToSection(speakerSandbox, speakerControls) {
    let { domHandler } = speakerSandbox;
    let $section = this.findSectionElem(
      domHandler.getSpeakerContextDomElement()
    );

    // For the section element, there will only be a single audio source (index 0) that we want to control
    domHandler._addSpeakerControlsToElement(
      $section,
      () => 0,
      speakerControls,
      { selectBeforePlay: false }
    );
  }

  applySpeakerMarkUpToStoryImage(
    domHandler,
    {
      numberOfAudioSources,
      highlightAsSelected,
      highlightAsPlaying,
      highlightAsPaused,
      progressBarValue = 0,
    }
  ) {
    let $storyImage = this.findStoryImageElem(
      domHandler.getSpeakerContextDomElement()
    );

    // Showing a progress bar when there are more than 1 audio sources doesn't make sense in "image only mode", since texts are not visible
    // and there will only be one image regardless of the number of audio sources
    let showProgressBar = numberOfAudioSources === 1;

    if (
      $storyImage.length > 0 &&
      !domHandler._hasTextBlockSpeakerMarkUp($storyImage)
    ) {
      domHandler._applySpeakerMarkUpToElem($storyImage, {
        // The progress bar can be disabled completely for story image using the showProgressBar param. That's necessary to not show it when there are more than one text block being played.
        showProgressBar:
          showProgressBar && domHandler._shouldShowProgressBarWhenPlaying(true),
      });

      if (progressBarValue > 0) {
        domHandler._setProgressBarValue($storyImage, progressBarValue);
      }

      if (highlightAsSelected) {
        this._highlightSelectedStoryImage(domHandler);
      }
      if (highlightAsPlaying) {
        this._highlightStoryImagePlaying(domHandler, $storyImage, {
          isUserTriggered: false,
        });
      } else if (highlightAsPaused) {
        this._highlightStoryImagePaused(domHandler, $storyImage);
      }
    }
  }

  _showStoryImageLoader(domHandler) {
    let $storyPlayer = this._findSpeakerControlsForStoryImage();
    if ($storyPlayer.length > 0) {
      // Insert the loading indicator as a sibling after the speaker controls
      $storyPlayer.after(domHandler._getLoadingIndicatorHtml());
    }
  }

  _hideStoryImageLoader(domHandler) {
    domHandler._hideTextBlockLoader(
      this._findSpeakerControlsForStoryImage().parent()
    );
  }

  _highlightSelectedStoryImage(domHandler) {
    domHandler._highlightSelected(
      this.findStoryImageElem(domHandler.getSpeakerContextDomElement())
    );
  }

  _highlightSelectedSection(domHandler) {
    domHandler._highlightSelected(
      this.findSectionElem(domHandler.getSpeakerContextDomElement())
    );
  }

  _highlightSectionPlaying(domHandler, { isUserTriggered = true } = {}) {
    // Clear any paused/playing highlighting
    this._clearSectionPlayingHighlighting(domHandler);
    this._clearSectionPausedHighlighting(domHandler);

    let $section = this.findSectionElem(
      domHandler.getSpeakerContextDomElement()
    );

    domHandler._highlightTextBlockPlaying($section, {
      // Always treat the legacy section speech as recorded speech, since that will show animation if enabled, even if it's synthetic speech being played.
      highlightWithAnimation: domHandler._shouldShowAnimationWhenPlaying(true),
      isUserTriggered,
    });
  }

  _highlightStoryImagePlaying(
    domHandler,
    $storyImage,
    { isUserTriggered = false } = {}
  ) {
    // Clear any playing/paused highlighting
    this._clearStoryImagePausedHighlighting(domHandler);
    this._clearStoryImagePlayingHighlighting(domHandler);

    domHandler._highlightTextBlockPlaying($storyImage, {
      // Always treat the story image speech as recorded speech, since that will show animation if enabled, even if it's synthetic speech being played.
      highlightWithAnimation: domHandler._shouldShowAnimationWhenPlaying(true),
      isUserTriggered,
    });
  }

  _highlightSectionPaused(domHandler) {
    domHandler._highlightTextBlockPaused(
      this.findSectionElem(domHandler.getSpeakerContextDomElement())
    );
  }

  _highlightStoryImagePaused(domHandler) {
    domHandler._highlightTextBlockPaused(
      this.findStoryImageElem(domHandler.getSpeakerContextDomElement())
    );
  }

  _clearSelectedSectionHighlighting(domHandler) {
    domHandler._clearSelectedHighlighting(
      this.findSectionElem(domHandler.getSpeakerContextDomElement())
    );
  }

  _clearSelectedStoryImageHighlighting(domHandler) {
    domHandler._clearSelectedHighlighting(
      this.findStoryImageElem(domHandler.getSpeakerContextDomElement())
    );
  }

  _clearSectionPlayingHighlighting(domHandler) {
    domHandler._clearPlayingHighlighting(
      this.findSectionElem(domHandler.getSpeakerContextDomElement())
    );
  }

  _clearStoryImagePlayingHighlighting(domHandler) {
    domHandler._clearPlayingHighlighting(
      this.findStoryImageElem(domHandler.getSpeakerContextDomElement())
    );
  }

  _clearSectionPausedHighlighting(domHandler) {
    domHandler._clearPausedHighlighting(
      this.findSectionElem(domHandler.getSpeakerContextDomElement())
    );
  }

  _clearStoryImagePausedHighlighting(domHandler) {
    domHandler._clearPausedHighlighting(
      this.findStoryImageElem(domHandler.getSpeakerContextDomElement())
    );
  }

  // END: Functionality to handle Digilär's story mode
  //
  ////////////////////////////////////////////////////////////////////
}

export default DigilarDomHelper;
