import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';
import { getScrollParent } from 'compton/utils/dom';
import { load } from 'ember-async-data';
import { resolve } from 'rsvp';

export default class CollectionViewComponent extends Component {
  /**
   * Arguments:
   * collection (object)
   * exercises (array)
   * activeExercise (object)
   * answers (array)
   * activeAnswer (object)
   * shortCode (string)
   * editor (object)
   * openExercise (function)
   * disabled (bool)
   * onCollectionClose (function)
   * digilarRecordedSpeechEnabled (boolean)
   * iltRecordedSpeechEnabled (boolean)
   * showCollectionSummary (boolean)
   * toggleCollectionSummary (function)
   */

  constructor() {
    super(...arguments);
    this.resizeObserver = new ResizeObserver(this.handleResize);
  }

  willDestroy() {
    super.willDestroy(...arguments);

    this.args.toggleCollectionSummary?.(false);
    this.resizeObserver.unobserve(this.element);
    this.resizeObserver.unobserve(this.scrollParent);
  }

  @service assignmentEvents;

  @service media;

  @service missionMode;

  @service showResponse;

  @tracked element;

  @tracked scrollParent;

  @tracked elementWidth;

  @tracked resizeObserver;

  @tracked showKey = false;

  @tracked showSuggestion = false;

  @tracked isCreatingNewCarouselAttempt = false;

  @cached
  get interactiveProxy() {
    const promise = this.assignmentEvents
      .getOrCreateInteractiveForCollection(
        this.args.collection,
        this.args.activeExercise
      )
      .then(({ interactive }) => interactive);

    return load(promise, this);
  }

  get interactive() {
    return this.interactiveProxy.isResolved
      ? this.interactiveProxy.value
      : null;
  }

  @cached
  get exercisesProxy() {
    let promise;

    if (this.args.collection?.isCarousel && this.interactive) {
      promise = this.assignmentEvents.carouselAttemptExercises(
        this.interactive
      );
    } else {
      promise = resolve(this.args.exercises);
    }

    return load(promise, this);
  }

  get exercises() {
    return this.exercisesProxy.isResolved ? this.exercisesProxy.value : [];
  }

  get showCarouselResult() {
    return this.args.collection?.isCarousel && this.interactive?.ended;
  }

  get isVisible() {
    return (
      this.missionMode.entityIsAllowed(this.args.collection) &&
      this.exercises?.length > 0
    );
  }

  get hasPagination() {
    return !(
      this.args.collection?.isCarousel || this.args.showCollectionSummary
    );
  }

  get disabled() {
    if (this.args.collection?.isCarousel) {
      if (
        !['not-started', 'started', 'incomplete'].includes(
          this.args.activeAnswer?.status
        )
      ) {
        return true;
      }
    }

    return this.args.disabled;
  }

  get audioSource() {
    return this.args.collection?.audio?.meta?.attachment_url;
  }

  get hasEditor() {
    return this.args.editor?.components?.length > 0 ? true : false;
  }

  get stacked() {
    if (!this.args.collection) return true;
    if (this.args.collection?.isCarousel) return true;
    if (this.elementWidth < 992) return true;
    return false;
  }

  get inNarrowSpace() {
    if (this.elementWidth < 520) return true;
    return false;
  }

  get hasAudio() {
    return !this.disabled && this.audioSource;
  }

  get speakerContentInfo() {
    return {
      contentTitle: this.args.activeExercise?.title,
      shortCode: this.args.shortCode,
      digilarRecordedSpeechEnabled: this.args.digilarRecordedSpeechEnabled,
      iltRecordedSpeechEnabled: this.args.iltRecordedSpeechEnabled,
    };
  }

  get exerciseIndex() {
    return this.exercises?.indexOf(this.args.activeExercise) + 1;
  }

  get classes() {
    const classes = ['collection-view'];

    if (this.stacked) classes.push('collection-view-stacked');
    if (this.inNarrowSpace) classes.push('collection-view-narrow');

    return classes.join(' ');
  }

  @action
  handleCreateNewCarouselAttempt() {
    this.isCreatingNewCarouselAttempt = true;
  }

  @action
  handleNewCarouselAttemptCreated() {
    this.isCreatingNewCarouselAttempt = false;
  }

  @action
  changedCollection() {
    this.showKey = false;
    this.showSuggestion = false;
  }

  @action
  toggleKey(val) {
    if (val === true || val === false) this.showKey = val;
    else this.showKey = !this.showKey;
  }

  @action
  toggleSuggestion(val) {
    if (val === true || val === false) this.showSuggestion = val;
    else this.showSuggestion = !this.showSuggestion;
  }

  @action
  setElement(element) {
    this.element = element;
    this.scrollParent = getScrollParent(element);

    this.resizeObserver.observe(this.element);
    this.resizeObserver.observe(this.scrollParent);

    this.handleResize();
  }

  @action
  nextExercise() {
    this.showResponse.reset();

    if (this.args.collection?.isCarousel) {
      this.showKey = false;
      this.showSuggestion = false;

      return this.assignmentEvents.carouselOpenNextExercise(this.interactive);
    }

    return this.changeExercise(this.exerciseIndex + 1);
  }

  @action
  async handleExerciseBottombarNextExercise() {
    const nextExercise = this.exercises?.objectAt(this.exerciseIndex);

    if (nextExercise) {
      this.assignmentEvents.focusExercise(nextExercise);
    }

    return this.nextExercise();
  }

  @action
  async changeExercise(page) {
    if (this.args.showCollectionSummary) {
      this.args.toggleCollectionSummary?.(false);
    }

    const exercise = this.exercises?.objectAt(page - 1);

    if (!exercise) return;

    this.showKey = false;
    this.showSuggestion = false;

    await this.args.openExercise(exercise.id);

    this.scrollParent.scrollTo({ top: 0 });

    return exercise;
  }

  @action
  handleResize() {
    if (!this.element) return;

    const { top: elementTop, width: elementWidth } =
      this.element.getBoundingClientRect();

    if (this.elementWidth !== elementWidth) {
      this.elementWidth = elementWidth;
    }

    let collectionViewTop = 0;

    if (this.scrollParent === document.body) {
      collectionViewTop = elementTop + document.documentElement.scrollTop;
    } else {
      collectionViewTop = elementTop + this.scrollParent?.scrollTop;
    }

    if (this.scrollParent === document.body) {
      this.element.style.setProperty(
        '--collection-view-top',
        `${elementTop}px`
      );
    } else {
      this.element.style.setProperty(
        '--collection-view-top',
        `${collectionViewTop}px`
      );
    }

    this.element.style.setProperty(
      '--collection-view-index-offset-top',
      `${collectionViewTop}px`
    );

    if (!this.scrollParent) return;

    const { bottom: scrollParentBottom, top: scrollParentTop } =
      this.scrollParent.getBoundingClientRect();

    this.element.style.setProperty(
      '--collection-scroll-parent-top',
      `${scrollParentTop}px`
    );

    if (this.scrollParent.tagName.toLowerCase() !== 'body') {
      const marginBottom = window.innerHeight - scrollParentBottom;

      this.element.style.setProperty(
        '--collection-scroll-parent-bottom',
        `${marginBottom}px`
      );
    }
  }
}
