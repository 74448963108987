import { A } from '@ember/array';
import { computed, observer } from '@ember/object';
import { alias, readOnly, setDiff, sort } from '@ember/object/computed';
import { on } from '@ember/object/evented';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import ContentsController from 'babel/controllers/master/contents-refactored';
import { nextObject, previousObject } from 'compton/utils/array';
import { storageFor } from 'ember-local-storage';

import animateToSection from '../../utils/animate-to-section';
import DigilarSpeakerConfig from '../../utils/speaker/DigilarSpeakerConfig';

const TEMPLATE = ['chapter', 'story', 'workflow'];

export default ContentsController.extend({
  // SETUP

  contextHelper: service(),

  missionMode: service(),

  speaker: service(),

  session: service(),

  router: service(),

  pageviews: service(),

  settings: storageFor('read-setting'),

  speakerSettings: storageFor('play-settings'),

  queryParams: [
    'viewAs',
    { activeExerciseId: 'exercise' },
    { activeSectionId: 'section' },
    { missionId: 'mission' },
    { customContentId: 'custom_content' },
    { closeUrl: 'close_url' },
    { returnUrl: 'return_url' },
  ],

  // PROPERTIES

  content: readOnly('model'),

  contentId: alias('content.id'),

  bookId: alias('content.book.id'),

  activeSectionId: null,

  missionId: null,

  customContentId: null,

  viewAs: null,

  lastTransitionWasWithinWorkspace: false,

  activeSection: computed(
    'activeSectionId',
    'content.body.skip_cover_page',
    'restrictedSections.firstObject.id',
    'sections.[]',
    'preferredCategory.id',
    function () {
      let sectionId = this.activeSectionId;

      if (
        (!sectionId || !this.sections.findBy('id', sectionId)) &&
        this.content?.body?.skip_cover_page === true
      ) {
        if (this.preferredCategory) {
          sectionId = this.restrictedSections?.filter(
            (section) =>
              section.belongsTo('cat').id() === this.preferredCategory.id
          )?.firstObject?.id;
        }

        if (!sectionId) {
          sectionId = this.restrictedSections?.firstObject?.id;
        }
      }

      return this.sections.findBy('id', sectionId);
    }
  ),

  // NOTE This property is used by Ember Router Scroll to determine whether
  // the current scroll position should be preserved on a route change. See:
  // https://github.com/DockYard/ember-router-scroll#preservescrollposition-with-a-controller-property
  preserveScrollPosition: computed(
    'activeSection',
    'settings.mode',
    'template',
    'router.currentRoute',
    'lastTransitionWasWithinWorkspace',
    function () {
      if (!this.activeSection) {
        return false;
      }

      if (
        this.get('settings.mode') === 'scroll' &&
        this.template === 'chapter'
      ) {
        return true;
      }

      // NOTE When the mode is 'browse' and the template is 'story' or
      // 'workflow', we only want to preserve the scroll position if the last
      // transition was within the workspace.
      return this.lastTransitionWasWithinWorkspace;
    }
  ),

  template: computed('viewAs', 'content.template', function () {
    const viewAs = this.viewAs;

    if (TEMPLATE.includes(viewAs)) {
      return viewAs;
    }

    let template = this.get('content.template');

    if (TEMPLATE.includes(template)) {
      return template;
    }

    return TEMPLATE.get('firstObject');
  }),

  includedEntities: alias('missionMode.includedEntities'),

  includedTeacherAssignments: alias('missionMode.includedTeacherAssignments'),

  sections: computed('content.children.[]', function () {
    return (this.get('content.children') || A()).filterBy('space', 'content');
  }),

  sortedSections: computed('sections.@each.{sort,category}', function () {
    return this.sections.sort((a, b) => {
      if (a.category?.sort !== b.category?.sort) {
        return a.category?.sort > b.category?.sort ? 1 : -1;
      } else {
        return a.sort > b.sort ? 1 : -1;
      }
    });
  }),

  restrictedSections: computed(
    'sortedSections.[]',
    'contextHelper.activeMission',
    'includedEntities.[]',
    function () {
      return this.sortedSections.filter((section) =>
        this.missionMode.entityIsAllowed(section)
      );
    }
  ),

  collections: computed('content.children.[]', function () {
    return this.content.children
      .filterBy('type', 'collections')
      .filterBy('space', 'workspace');
  }),

  restrictedCollections: computed(
    'collections.@each.{children,isTeacherCollection}',
    'content',
    'contextHelper.activeCourse',
    'includedEntities.[]',
    'includedTeacherAssignments.[]',
    'contextHelper.inMission',
    function () {
      return this.collections
        .filter((collection) => this.missionMode.entityIsAllowed(collection))
        .filter((collection) => {
          if (collection.get('isTeacherCollection')) {
            const teacherAssignment = collection.get(
              'children.firstObject.teacherAssignment'
            );
            const activeCourse = this.get('contextHelper.activeCourse');

            if (teacherAssignment?.hasContent(this.content)) {
              if (teacherAssignment.get('isOwner')) {
                return true;
              } else if (activeCourse) {
                return activeCourse
                  .hasMany('teachers')
                  .ids()
                  .includes(teacherAssignment.get('userId'));
              } else {
                return false;
              }
            }
            return false;
          }

          return true;
        });
    }
  ),

  // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
  sortedCollectionsOrder: [
    'teacherAssignment:desc',
    'user.isTeacher:desc',
    'sort',
    'user.showname',
    'title',
  ],

  sortedCollections: sort('restrictedCollections', 'sortedCollectionsOrder'),

  restrictedTeacherAssignments: computed(
    'contextHelper.activeCourse',
    'session.user.isStudent',
    'teacherAssignments.[]',
    'includedTeacherAssignments.[]',
    'contextHelper.inMission',
    function () {
      return this.teacherAssignments
        .filter((teacherAssignment) =>
          this.missionMode.teacherAssignmentIsAllowed(teacherAssignment)
        )
        .filter((teacherAssignment) => {
          const activeCourse = this.get('contextHelper.activeCourse');

          if (teacherAssignment.get('isOwner')) {
            return true;
          } else if (activeCourse) {
            return activeCourse
              .hasMany('teachers')
              .ids()
              .includes(teacherAssignment.get('userId'));
          } else {
            return false;
          }
        });
    }
  ),

  bookTeacherAssignments: computed(
    'restrictedTeacherAssignments.@each.{bookIds,created}',
    'content.book.id',
    function () {
      return this.restrictedTeacherAssignments
        .filter((teacherAssignment) =>
          teacherAssignment.get('bookIds').includes(this.get('content.book.id'))
        )
        .sortBy('created');
    }
  ),

  contentTeacherAssignments: computed(
    'restrictedTeacherAssignments.@each.{contentIds,created}',
    'content.id',
    function () {
      return this.restrictedTeacherAssignments
        .filter((teacherAssignment) =>
          teacherAssignment.get('contentIds').includes(this.get('content.id'))
        )
        .sortBy('created');
    }
  ),

  currentTeacherAssignments: readOnly('contentTeacherAssignments'),

  relatedTeacherAssignments: setDiff(
    'bookTeacherAssignments',
    'contentTeacherAssignments'
  ),

  /**
   * Additional information about the content that is required by the speaker.
   * Compiled into a single object from different parts of the model
   */
  speakerContentInfo: computed(
    'content.body.recorded_speech_activated',
    'content.book.body.{ilt_activated,shortcode}',
    'content.{sectionRecordedSpeech,title}',
    function () {
      const contentInfo = {
        contentTitle: this.get('content.title'),
        shortCode: this.get('content.book.body.shortcode'),
        useLegacyRecordedSpeechForStory: this.get(
          'content.sectionRecordedSpeech'
        ),
        iltRecordedSpeechEnabled:
          !!this.get('content.book.body.ilt_activated') &&
          !this.get('content.body.recorded_speech_activated'),
        digilarRecordedSpeechEnabled: !!this.get(
          'content.body.recorded_speech_activated'
        ),
      };

      return contentInfo;
    }
  ),

  // HOOKS

  init() {
    this._super(...arguments);
    this.digilarSpeakerConfig = new DigilarSpeakerConfig({
      getContextData: () => {
        return {};
      },
    });
  },

  // ACTIONS

  actions: {
    handleCategoryChange(category) {
      const newCategorySections = this.restrictedSections.filterBy(
        'category.id',
        category?.id
      );

      let section;

      if (newCategorySections.length === this.categorizedSections.length) {
        const activeIndex = this.categorizedSections.indexOf(
          this.activeSection
        );

        section = newCategorySections.objectAt(activeIndex).get('id');
      } else {
        section = newCategorySections.get('firstObject.id');
      }

      this.router.replaceWith({
        queryParams: { section },
      });
    },

    transitionToSection(section = undefined) {
      return animateToSection().then(() => {
        return this.router.transitionTo({
          queryParams: {
            section: section
              ? section
              : this.get('categorizedSections.firstObject.id'),
          },
        });
      });
    },

    routeToSection(sectionId) {
      this.router.transitionTo({
        queryParams: { section: sectionId, exercise: null },
      });
    },

    nextSection() {
      const nextSection = nextObject(
        this.categorizedSections,
        this.activeSection
      );

      if (nextSection) {
        this.router.replaceWith({
          queryParams: { section: nextSection.get('id') },
        });
        return true;
      }

      return false;
    },
    cacheNextAndPreviousImage(imageSize) {
      this._cacheNextAndPreviousImage(this.activeSection, imageSize);
    },

    previousSection() {
      const previousSection = previousObject(
        this.categorizedSections,
        this.activeSection
      );

      if (previousSection) {
        this.router.replaceWith({
          queryParams: { section: previousSection.get('id') },
        });
        return true;
      }

      return false;
    },

    handleExerciseChange() {
      if (this.activeExercise) {
        this.contextHelper.setActive('activeExercise', this.activeExercise);
      } else {
        this.contextHelper.setActive('activeExercise', null);
      }

      scheduleOnce('afterRender', this, '_trackActiveExercise');
    },
  },

  // OBSERVERS

  activeSectionObserver: on(
    'init',
    // eslint-disable-next-line ember/no-observers
    observer('activeSection', function () {
      if (this.isActive) {
        this.contextHelper.setActive('activeSection', this.activeSection);

        const user = this.session.user;
        const bookId = this.bookId;
        const categoryId = this.activeSection?.category?.id;

        if (user && this.contextHelper.inContent) {
          user.savePreferredTextVersion(bookId, categoryId);
        }

        scheduleOnce('afterRender', this, '_trackActiveSection');
      }
    })
  ),

  // PRIVATE
  async _cacheNextAndPreviousImage(section, imageSize) {
    const currentIndex = this._currentIndex(section);
    const previousSection = this.sections?.get(currentIndex - 1);
    const nextSection = this.sections?.get(currentIndex + 1);
    const previousImageUrl = previousSection?.image?.imageURL;
    const nextImageUrl = nextSection?.image?.imageURL;
    if (!previousImageUrl && !nextImageUrl) return;

    if (previousImageUrl) {
      await this._preloadImg(`${previousImageUrl}&size=${imageSize}`);
    }

    if (nextImageUrl) {
      await this._preloadImg(`${nextImageUrl}&size=${imageSize}`);
    }
  },

  _currentIndex(section) {
    return this.sections?.indexOf(section);
  },

  _preloadImg(imageUrl) {
    let cache = document.getElementById('image-cache-content-story');
    if (!cache) {
      cache = document.createElement('div');
      cache.id = 'image-cache-content-story';
      cache.style = 'position:absolute;z-index:-1000;opacity:0;';
      document.body.appendChild(cache);
    }

    if (this._divContainsImgWithSrc(cache, imageUrl)) return;

    var img = new Image();
    img.src = imageUrl;
    img.style = 'display:none';
    cache.appendChild(img);
    return;
  },

  _divContainsImgWithSrc(div, src) {
    const imgs = div.getElementsByTagName('img');
    return [...imgs].some((img) => img.src === src);
  },

  _sectionFromId(sectionId) {
    return this.sections.find((section) => section.id === sectionId);
  },

  _trackActiveSection() {
    const activeSection = this.activeSection;

    if (activeSection) {
      const currentRoute = this.get('router.currentRoute');

      const assignmentId = currentRoute.params.assignment_id;
      const wordlistId = currentRoute.parent.params.wordlist_id;

      const metadata = {};
      if (assignmentId) {
        metadata.assignment = assignmentId;
      } else if (wordlistId) {
        if (wordlistId) {
          const wordlist = this.currentWordlists.findBy('id', wordlistId);

          if (wordlist.get('isTransformed')) {
            metadata.wordlist = wordlistId;
          }
        }
      }

      const content = this.content;

      this.pageviews.logPageView(
        activeSection,
        content.get('book'),
        content,
        metadata
      );
    }
  },

  _trackActiveExercise() {
    const activeExercise = this.activeExercise;

    if (activeExercise) {
      const content = this.content;

      this.pageviews.logPageView(activeExercise, content.get('book'), content);
    }
  },
});
