import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import routeArgsForEntity from '../../utils/route-args-for-entity';

const { PromiseArray } = DS;

function createPromiseArray(promise) {
  return PromiseArray.create({ promise });
}

function extractRouteArgs(args) {
  args = args.slice();
  const possibleQueryParams = args[args.length - 1];

  let queryParams;
  if (
    possibleQueryParams &&
    Object.prototype.hasOwnProperty.call(possibleQueryParams, 'queryParams')
  ) {
    queryParams = args.pop().queryParams;
  } else {
    queryParams = {};
  }

  const routeName = args.shift();
  const models = args;

  return { routeName, models, queryParams };
}

// NOTE When passing the params property directly to Ember's link-to helper,
// it expects the query params to be in this format.
function buildQueryParamsHash(queryParams) {
  return {
    isQueryParams: true,
    values: queryParams,
  };
}

export default Component.extend({
  // SETUP

  contextHelper: service(),

  missionMode: service(),

  tagName: '',

  // PARAMS

  closeMissionHeader: false,

  entity: null,

  section: null,

  id: null,

  class: null,

  style: null,

  appearance: 'link',

  variant: 'default',

  size: 'medium',

  shape: 'square',

  // PROPERTIES

  initialQueryParams: computed('section.id', function () {
    const initialQueryParams = {};
    const closeMissionHeader = this.get('closeMissionHeader');
    const section = this.get('section');

    if (section) {
      initialQueryParams.section = this.get('section.id');
    }

    if (closeMissionHeader) {
      initialQueryParams.customContentId = null;
      initialQueryParams.missionId = null;
    }

    return initialQueryParams;
  }),

  routeArgs: computed(
    'entity',
    'initialQueryParams',
    'missionMode.{includedEntities.each,includedTeacherAssignments}',
    'contextHelper.inMission',
    function () {
      return createPromiseArray(
        routeArgsForEntity(
          this.get('entity'),
          this.get('initialQueryParams'),
          this.get('missionMode')
        )
      );
    }
  ),

  routeParams: computed(
    'routeArgs.content',
    'entity.id',
    'initialQueryParams',
    function () {
      const routeArgs = this.get('routeArgs.content');

      if (routeArgs) {
        const { routeName, models, queryParams } = extractRouteArgs(routeArgs);

        return [routeName, ...models, buildQueryParamsHash(queryParams)];
      }

      return [
        'nodes',
        this.get('entity.id'),
        buildQueryParamsHash(this.get('initialQueryParams')),
      ];
    }
  ),
});
