import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import babelEnv from 'babel/config/environment';

const STATES = {
  paused: 'paused',
  listening: 'listening',
  speaking: 'speaking',
  notStarted: 'notStarted',
  ended: 'ended',
};

export default class ReadingBuddyComponent extends Component {
  azureApiKey = babelEnv.talkamatic?.azureApiKey;
  rootURL = babelEnv.rootURL;

  @service intl;

  @tracked currentState = STATES.notStarted;
  @tracked ttsLexicon = 'https://cvoiceprodneu.blob.core.windows.net/acc-public-files/33867cc7d3ce43b9a040dc16212a025e/9f6c85e1-4fe9-4268-b337-24015d30b46c.xml';
  @tracked showWidget = true;
  @tracked isActivated = false;
  @tracked isLoading = false;

  get avatar() {
    return `/assets/images/reading-buddy/dog_${this.currentState}.svg`;
  }

  get displayText() {
    return this.intl.t(`components.readingBuddy.states.${this.currentState}`);
  }

  get readingBuddyClass() {
    return this.inPauseState ? 'reading-buddy-paused' : 'reading-buddy-playing';
  }

  get readingBuddyActiveClass() {
    return this.isActivated ? 'reading-buddy-activated' : '';
  }

  get inPauseState() {
    return this.isPaused || !this.isActivated;
  }

  get isListening() {
    return this.currentState === STATES.listening;
  }

  get isSpeaking() {
    return this.currentState === STATES.speaking;
  }

  get isPaused() {
    return this.currentState === STATES.paused;
  }

  get isEnded() {
    return this.currentState === STATES.ended;
  }

  // Actions

  @action handleRestart() {
    this.reset();
  }

  @action handleClick() {
    this.isLoading = true;
    if (!this.isActivated || this.isEnded) {
      this.activate();
    } else {
      this.isPaused ? this.dispatchActivate() : this.dispatchPause();
    }
  }

  @action end() {
    this.currentState = STATES.ended;
    this.isActivated = false;
  }

  @action turnPage(event) {
    window.availableDDDs = event.detail;
    console.log(window.availableDDDs);
    this.dispatchTurnPage();
  }

  @action resetPage() {
    if (this.args.resetOnPageChange) this.reset();
  }

  @action stateListener(event) {
    const eventDetail = event.detail;
    const eventType = eventDetail.value?.asrtts?.ready?.recognising;
    const mainEvent = eventDetail?.event.type;

    console.log({
      eventType,
      mainEvent,
      eventDetail,
    });

    if (eventDetail.value?.dm === 'end') {
      this.end();
      return;
    }

    console.log(eventDetail);

    switch (mainEvent) {
      case 'SPEAK':
        this.isActivated = true;
        this.currentState = STATES.speaking;
        this.isLoading = false;
        break;
      case 'LISTEN':
        this.currentState = STATES.listening;
        this.isLoading = false;
        break;
      case 'PAUSE':
        this.currentState = STATES.paused;
        this.isLoading = false;
        break;
      case 'ASR_START':
        this.currentState = STATES.listening;
        this.isLoading = false;
        break;
      case 'CLICK':
        if (eventType === 'pause') {
          this.currentState = STATES.paused;
          this.isLoading = false;
        } else if (eventDetail.value?.asrtts?.ready?.speaking === 'go') {
          this.currentState = STATES.speaking;
          this.isLoading = false;
        }
        break;
    }
  }

  // Functions

  dispatchPause() {
    window.dispatchEvent(new CustomEvent('talaPause'));
  }

  dispatchActivate() {
    window.dispatchEvent(new CustomEvent('talaClick'));
  }

  dispatchStop() {
    window.dispatchEvent(new CustomEvent('talaKill'));
  }

  dispatchTurnPage() {
    window.dispatchEvent(
      new CustomEvent('turnpage', {
        detail: { dddName: this.args.page },
      })
    );
  }

  activate() {
    this.isActivated = true;
    this.dispatchActivate();
  }

  reset() {
    if (this.isActivated) {
      this.dispatchStop();
      this.isActivated = false;
      this.rerender();
    }
    this.dispatchTurnPage();
    this.currentState = STATES.notStarted;
  }

  rerender() {
    this.showWidget = false;
    setTimeout(() => {
      this.showWidget = true;
      this.isLoading = false;
    }, 2);
  }

  // Life cycle hooks

  constructor() {
    super(...arguments);
    window.addEventListener('talaSpeechState', this.stateListener, true);
    window.addEventListener('setAvailableDDDs', this.turnPage, true);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.dispatchStop();
    window.removeEventListener('talaSpeechState', this.stateListener, true);
    window.removeEventListener('setAvailableDDDs', this.turnPage, true);
  }
}
