import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ThemeSwitchComponent extends Component {
  @service colorTheme;
  @service intl;

  @tracked themeOptions;

  get currentTheme() {
    return this.colorTheme.currentTheme;
  }

  constructor() {
    super(...arguments);

    this.themeOptions = [
      {
        label: this.intl.t('components.themeSwitch.colorSchemes.standard'),
        value: 'light',
      },
      {
        label: this.intl.t('components.themeSwitch.colorSchemes.dark'),
        value: 'dark',
      },
      {
        label: this.intl.t('components.themeSwitch.colorSchemes.dyslexi'),
        value: 'dyslexi',
      },
    ];
  }

  @action setTheme(value) {
    this.colorTheme.changeTheme(value);
  }
}
