import Component from '@ember/component';
import { computed } from '@ember/object';
import { map } from '@ember/object/computed';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import generateFallbackTitle from 'babel/utils/generate-fallback-title';
import DS from 'ember-data';
import { v4 as uuid } from 'ember-uuid';
import { hash, resolve } from 'rsvp';

import copyToClipboard from '../../../utils/copy-to-clipboard';
import routeArgsForEntity from '../../../utils/route-args-for-entity';

const { PromiseArray, PromiseObject } = DS;

function createPromiseObject(promise) {
  return PromiseObject.create({ promise });
}

function createPromiseArray(promise) {
  return PromiseArray.create({ promise });
}

const SECTIONS = 'sections';

export default Component.extend({
  // SETUP

  session: service(),

  router: service(),

  intl: service(),

  snackbar: service(),

  entityInspector: service(),

  contextHelper: service(),

  missionMode: service(),

  classNames: ['entity-browser-detail'],

  // PARAMS

  entity: null,

  section: null,

  version: null,

  onLoadStart: null,

  onLoadEnd: null,

  book: computed('entity.{type,book}', function () {
    if (this.get('entity.type') === 'books') return this.entity;
    return this.get('entity.book');
  }),

  entityPromise: computed('entity', 'versions', function () {
    return hash({
      ...this.entity.getProperties('children', 'guidances'),
      versions: this.versions,
    });
  }),

  versions: computed(
    'entity.children.[]',
    'missionMode.includedEntities.each',
    'contextHelper.{activeContent,inMission}',
    function () {
      const promise = this.entity.children.then((children) => {
        const versions = [];

        const categories = children
          .filter((child) => this._entityIsAllowed(child))
          .filterBy('type', SECTIONS)
          .mapBy('category')
          .uniq();

        if (categories.length > 0) {
          versions.addObjects(
            categories.map((category) => this._createVersion(category))
          );
        } else {
          versions.addObject(this._createVersion());
        }

        return Promise.all(versions);
      });

      return createPromiseArray(promise);
    }
  ),

  routeArgs: computed('entity', function () {
    return createPromiseObject(
      routeArgsForEntity(this.entity, {}, this.missionMode)
    );
  }),

  hasContentAuthors: computed('entity.{type,body.authors}', function () {
    if (this.get('entity.type') === 'contents') {
      const authors = this.get('entity.body.authors') || [];
      if (authors.length > 0) return true;
    }

    return false;
  }),

  authorNames: computed('entity.{book.body.authors,body.authors}', function () {
    let authors = this.get('entity.body.authors') || [];

    if (authors.length === 0) {
      authors = this.get('entity.book.body.authors') || [];
    }

    return authors.map((author) => {
      if (author.indexOf(',') !== -1) {
        const names = author.split(',');
        const firstName = names.pop().trim();
        const lastName = names.join(',');

        return { firstName: firstName, separator: ' ', lastName: lastName };
      }

      return { firstName: '', separator: '', lastName: author };
    });
  }),

  authors: map('authorNames', (author) => {
    return author.firstName + author.separator + author.lastName;
  }),

  source: computed('entity.id', 'intl', function () {
    const url = `${window.location.origin}/nodes/${this.get('entity.id')}`;
    const datetime = new Date().toISOString().substring(0, 10);

    return htmlSafe(
      this.intl.t('components.entityInspector.detail.sourceString', {
        url,
        datetime,
      })
    );
  }),

  type: computed('entity.type', 'intl', function () {
    return generateFallbackTitle(this.entity?.type, this.intl);
  }),

  updated: computed('entity.{latestUpdated,published}', function () {
    let date;

    if (this.get('entity.latestUpdated')) {
      date = this.get('entity.latestUpdated');
    } else if (this.get('entity.published')) {
      date = this.get('entity.published');
    } else {
      return null;
    }

    return (
      date.toISOString().substring(0, 10) + ' ' + date.toLocaleTimeString()
    );
  }),

  // ACTIONS

  actions: {
    handleGoToButtonClick() {
      this._goTo();
    },

    addToClipboard(key) {
      return resolve(this.get(key)).then((val) => {
        if (!val) return;

        if (key === 'source') {
          const element = document.querySelector('.content-info-source');
          element.classList.add('copied-reference');

          later(() => {
            element.classList.remove('copied-reference');
          }, 500);
        }

        return copyToClipboard(val)
          .then(() => {
            this.snackbar.enqueue(
              this.intl.t(
                'components.entityInspector.detail.' + key + 'CopiedToClipboard'
              ),
              {
                variant: 'success',
                autoDismiss: true,
              }
            );
          })
          .catch(() => {
            this.snackbar.enqueue(
              this.intl.t(
                'components.entityInspector.detail.' +
                  key +
                  'FailedToCopyToClipboard'
              ),
              {
                variant: 'error',
              }
            );
          });
      });
    },

    closeModal() {
      this._goTo();
      this.onClose();
    },
  },

  _goTo() {
    return this.routeArgs.then((routeArgs) => {
      this.router.transitionTo(...routeArgs);
      this.entityInspector.closeModal();
    });
  },

  async _createVersion(category) {
    const [children, materialEntityGroups] = await Promise.all([
      this.entity.children,
      this.entity.copyrightedMaterialEntityGroups,
    ]);

    const version = {
      uuid: uuid(),
      name: category?.name,
    };

    let sections = children
      .filterBy('type', SECTIONS)
      .filter((child) => this._entityIsAllowed(child))
      .filter((child) => {
        if (category) {
          return child.category === category;
        }
        return true;
      });

    if (sections.length > 0) {
      version.meta = {
        sections: sections.length,
        words: 0,
        minutes: 0,
      };

      const promises = sections.map(async (section) => {
        const editor = await section.get('contenteditor');

        if (editor) {
          if (section.heading?.length > 0) {
            version.meta.words += section.heading.split(' ').length;
          }

          if (editor.metadata?.words) {
            version.meta.words += editor.metadata.words;
          }
        }
      });

      await Promise.all(promises);

      version.meta.minutes = Math.max(
        1,
        Math.ceil(parseInt(version.meta.words) / 120)
      );
    }

    version.materialEntityGroups = materialEntityGroups
      .filter((group) => {
        if (group.entity.type === SECTIONS && group.entity.category) {
          return group.entity.category === category;
        }
        return true;
      })
      .filter((group) => {
        if (group.entity.type === SECTIONS) {
          return this._entityIsAllowed(group.entity);
        }
        return true;
      })
      .map((group) => {
        if (group.entity.type === SECTIONS) {
          return {
            ...group,
            index: sections.indexOf(group.entity) + 1,
          };
        }
        return group;
      });

    return version;
  },

  _entityIsAllowed(entity) {
    if (
      this.contextHelper.inMission &&
      this.contextHelper.activeContent === this.entity
    ) {
      return this.missionMode.entityIsAllowed(entity);
    }
    return true;
  },
});
