import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class MasterInteractivesIndexController extends Controller {
  @service contextHelper;

  @service missionMode;

  get interactive() {
    return this.model;
  }

  get restrictedCollections() {
    return this.interactive?.children
      ?.filter((child) => {
        if (this.contextHelper.inMission) {
          return this.missionMode.entityIsAllowed(child);
        }

        return true;
      })
      ?.sortBy('sort');
  }
}
