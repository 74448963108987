import { A } from '@ember/array';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import {
  getValueFromObject,
  removeObject,
  storeInObject,
} from 'babel/utils/session-storage';
import { storageFor } from 'ember-local-storage';
import { resolve } from 'rsvp';

const SESSION_STORAGE_KEY = 'course_context_dir';

export default class CourseContextService extends Service {
  @service contextHelper;
  @service store;
  @service session;

  @storageFor('content-visited') visits;

  async setCurrentCourse(course) {
    course = await resolve(course);

    if (course) {
      await course.books;

      if (course.books) {
        course.books.forEach((book) => {
          this._storeActiveCourseForBook(book.id, course.id);
        });
      }
    }

    this.contextHelper.setActive('activeCourse', course);
  }

  removeAllContext() {
    this.contextHelper.setActive('activeCourse', null);
    removeObject(SESSION_STORAGE_KEY);
  }

  async fetchCoursesForProduct(product) {
    let currentCourses = A();
    const courses = await this.session.get('user.courses');
    courses?.forEach((course) => {
      course.products.forEach((courseProduct) => {
        if (product.id === courseProduct.id) {
          currentCourses.pushObject(course);
        }
      });
    });
    currentCourses = currentCourses.uniq();
    return currentCourses;
  }

  async fetchCoursesForBook(bookEntity) {
    let currentCourses = A();
    const courseId = this._getActiveCourseForBook(bookEntity.get('id'));

    if (courseId) {
      const courses = await this.session.get('user.courses');
      const current = courses.findBy('id', courseId);

      if (current) {
        this.currentCourse = current;
        currentCourses.pushObject(current);
      }
    }

    if (!currentCourses.length) {
      const products = await bookEntity.get('products');
      const courses = await this.session.get('user.courses');

      products?.forEach((product) => {
        courses.forEach((course) => {
          course?.get('products').forEach((courseProduct) => {
            if (courseProduct.get('id') == product.get('id')) {
              currentCourses.pushObject(course);
            }
          });
        });
      });

      currentCourses = currentCourses.uniq();
    }
    return currentCourses;
  }

  storeVisitedContent(entityId) {
    if (this.contextHelper.inCourse) {
      let visits = this.visits.get('content_visited');
      if (!Array.isArray(visits[this.contextHelper.activeCourse.id])) {
        visits[this.contextHelper.activeCourse.id] = [];
      }
      visits[this.contextHelper.activeCourse.id] = visits[
        this.contextHelper.activeCourse.id
      ].filter((e) => e !== entityId);
      visits[this.contextHelper.activeCourse.id].unshift(entityId);
      this.visits.set('content_visited', visits);
    }
  }

  getVisitedContentIds(courseId) {
    return this.visits.get('content_visited')[courseId];
  }

  // PRIVATE

  _getActiveCourseForBook(bookId) {
    return getValueFromObject(SESSION_STORAGE_KEY, bookId);
  }

  _storeActiveCourseForBook(bookId, courseId) {
    storeInObject(SESSION_STORAGE_KEY, bookId, courseId);
  }
}
