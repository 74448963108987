import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<XTab\n  @value={{this.value}}\n  @onChange={{fn (mut this.value)}}\n  ...attributes\n  as |tab|\n>\n\n  {{#each this.versions as |version|}}\n\n    <EntityInspector::Detail::VersionTab::Pane\n      @tab={{tab}}\n      @version={{version}}\n    />\n\n  {{/each}}\n\n</XTab>", {"contents":"<XTab\n  @value={{this.value}}\n  @onChange={{fn (mut this.value)}}\n  ...attributes\n  as |tab|\n>\n\n  {{#each this.versions as |version|}}\n\n    <EntityInspector::Detail::VersionTab::Pane\n      @tab={{tab}}\n      @version={{version}}\n    />\n\n  {{/each}}\n\n</XTab>","moduleName":"babel/components/entity-inspector/detail/version-tab/index.hbs","parseOptions":{"srcName":"babel/components/entity-inspector/detail/version-tab/index.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import arg from 'compton/utils/arg';

export default class EntityInspectorDetailVersionTabComponent extends Component {
  // ARGS

  @arg versions;

  @arg initialVersionName;

  @tracked value = this.initialVersion;

  // PROPERTIES

  get initialVersion() {
    const version =
      this.versions.findBy('name', this.initialVersionName) ??
      this.versions.firstObject;

    return version?.uuid;
  }
}
