import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class WorkspaceAssignmentsShowComponent extends Component {
  /**
   * Arguments:
   * model (object)
   * onClose (function)
   */

  @service router;

  @tracked isModalOpen = false;

  @tracked showCollectionSummary = false;

  @action
  openModal() {
    this.isModalOpen = true;
  }

  @action
  closeModal() {
    this.isModalOpen = false;
  }

  @action
  openIndex() {
    return this.router.transitionTo('master.contents.assignments');
  }

  @action
  openExercise(exerciseId) {
    return this.router.transitionTo(
      'master.contents.assignments.show',
      exerciseId
    );
  }

  @action
  toggleCollectionSummary(val) {
    if (typeof val === 'boolean') this.showCollectionSummary = val;
    else this.showCollectionSummary = !this.showCollectionSummary;
  }
}
