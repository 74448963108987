import { computed } from '@ember/object';
import { alias, or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import { isPresent } from '@ember/utils';
import DS from 'ember-data';
import { singularize } from 'ember-inflector';
import { all, resolve } from 'rsvp';

const { PromiseArray } = DS;

function createPromiseArray(promise) {
  return PromiseArray.create({ promise });
}
import Model from './model';

const { attr } = DS;

export default Model.extend({
  intl: service(),

  store: service(),

  // ATTRIBUTES

  name: attr('string'),

  metadata: attr(),

  type: attr('string'),

  category: attr('string'),

  meta: attr(),

  rank: attr('number'), // for search results

  // RELATIONS

  entity: DS.belongsTo('entity', {
    async: true,
    inverse: null,
  }),

  editors: DS.hasMany('editor', {
    async: true,
    inverse: null,
  }),

  tagGroups: DS.hasMany('tag-group', {
    async: true,
    inverse: null,
  }),

  // ALIASES

  imageURL: alias('imageUrl'),

  imageUrl: computed('meta.image_url', 'updated', function () {
    const updated = this.updated || new Date();
    return (
      this.get('meta.image_url') +
      '?hash=' +
      this.generateHash(updated.toString())
    );
  }),

  copyright: readOnly('metadata.copyright'),

  originalImageURL: computed(
    'meta.properties.mimetype',
    'meta.{attachment_url,original_url}',
    'updated',
    function () {
      const updated = this.updated || new Date();

      if (this.get('meta.properties.mimetype') === 'image/svg+xml') {
        return (
          this.get('meta.original_url') +
          '?hash=' +
          this.generateHash(updated.toString())
        );
      } else {
        return (
          this.get('meta.attachment_url') +
          '?hash=' +
          this.generateHash(updated.toString())
        );
      }
    }
  ),

  materials: computed(
    'editors.@each.materials',
    'metadata.markers',
    'store',
    'type',
    function () {
      let promise = Promise.resolve([]);
      if (this.type === 'interactive_images') {
        promise = Promise.all(
          (this.metadata.markers || [])
            .filterBy('material_id')
            .map((marker) =>
              this.store.findRecord('material', marker.material_id)
            )
        ).then((materials) => materials.uniq());
      } else if (this.type === 'articles') {
        promise = this.editors.then((editors) =>
          Promise.all(editors.getEach('materials')).then((materials) =>
            materials.invoke('toArray').flat()
          )
        );
      }

      return createPromiseArray(promise);
    }
  ),

  copyrightedMaterials: computed(
    'metadata.copyright',
    'materials.@each.metadata',
    function () {
      return createPromiseArray(
        this.materials.then((materials) => {
          materials = materials.filter((material) =>
            isPresent(material.metadata.copyright)
          );

          if (this.metadata?.copyright) {
            materials.unshiftObject(this);
          }

          return materials;
        })
      );
    }
  ),

  imageColor: computed(
    'noShadow',
    'meta.{image_colors.firstObject,properties.colors.firstObject}',
    function () {
      if (this.noShadow) {
        return undefined;
      }

      return (
        this.meta?.image_colors?.firstObject ||
        this.meta?.properties?.colors?.firstObject
      );
    }
  ),

  backgroundColor: readOnly('imageColor'),

  // PROPERTIES

  nameWithFallback: computed('name', 'typeLabel', function () {
    const { name } = this;

    if (isPresent(name)) {
      return name;
    }

    return this.typeLabel;
  }),

  typeLabel: computed('type', 'intl.locale', function () {
    const { type } = this;

    if (type) {
      return this.intl.t(
        ['models', 'material', 'typeLabel', camelize(type)].join('.')
      );
    }
    return null;
  }),

  selected: false,

  typeSingular: computed('type', function () {
    return singularize(this.type);
  }),

  imageMimetype: readOnly('meta.image_mimetype'),

  imageWidth: or('meta.image_width', 'metadata.width'),

  imageHeight: or('meta.image_height', 'metadata.height'),

  noShadow: or('meta.no_shadow', 'metadata.no_shadow'),

  imageAspectRatio: computed('imageWidth', 'imageHeight', function () {
    const { imageWidth, imageHeight } = this.getProperties(
      'imageWidth',
      'imageHeight'
    );

    if (imageWidth != null && imageHeight != null) {
      return (imageHeight / imageWidth) * 100;
    }

    return null;
  }),

  imageIsPortrait: computed('imageWidth', 'imageHeight', function () {
    const { imageWidth, imageHeight } = this.getProperties(
      'imageWidth',
      'imageHeight'
    );
    return imageWidth < imageHeight;
  }),

  generateHash(s) {
    return s.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  },
});
