export default function qrCodeSource(text, { width = 512, height = 512 } = {}) {
  const element = document.createElement('div');
  document.body.appendChild(element);

  // QRCode is a global variable
  // eslint-disable-next-line no-undef
  new QRCode(element, {
    text,
    width,
    height,
  });

  const canvas = element.querySelector('canvas');
  const dataUrl = canvas.toDataURL();

  document.body.removeChild(element);

  return dataUrl;
}
