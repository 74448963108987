import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import copyToClipboard from 'babel/utils/copy-to-clipboard';
import qrCodeSource from 'babel/utils/qr-code-source';

function downloadSource(source, filename) {
  const link = document.createElement('a');
  link.download = filename;
  link.href = source;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default Component.extend({
  session: service(),

  modalTitle: null,

  entity: null,

  fullLink: null,

  title: null,

  body: null,

  hideOptions: false,

  snackbar: service(),

  intl: service(),

  qrModalIsOpen: false,

  includeProvider: true,

  link: null,

  finalLink: computed(
    'includeProvider',
    'link',
    'session.user.provider',
    function () {
      const url = new URL(this.link || window.location.href);

      const provider = this.session.user.provider;

      if (provider && this.includeProvider) {
        url.searchParams.set('provider', provider);
      } else {
        url.searchParams.delete('provider');
      }

      return url.toString();
    }
  ),

  didReceiveAttrs() {
    this._super(...arguments);
    if (!this.fullLink) {
      this.set(
        'title',
        this.intl.t('components.shareLinkModal.message.title', {
          title: this.get('entity.title'),
        })
      );

      this.set(
        'body',
        this.intl.t('components.shareLinkModal.message.body', {
          title: this.get('entity.title'),
        })
      );
    }
  },

  actions: {
    selectAll(input) {
      input.setSelectionRange(0, input.value.length);
    },

    handleDownloadQrCodeButtonClick() {
      downloadSource(qrCodeSource(this.finalLink), 'qr-code.png');
    },
    handleCopyToClipboard(value) {
      copyToClipboard(value)
        .then(() => {
          this.snackbar.enqueue(
            this.intl.t('components.shareLinkModal.locationCopiedToClipboard'),
            {
              variant: 'success',
              autoDismiss: true,
            }
          );
        })
        .catch(() => {
          this.snackbar.enqueue(
            this.intl.t(
              'components.shareLinkModal.locationFailedToCopyToClipboard'
            ),
            {
              variant: 'error',
            }
          );
        });
    },
  },
});
